
  var editorScriptEntry = require('/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/editor.app.ts');

  
    if (editorScriptEntry.default) {
      module.exports = editorScriptEntry.default;
    } else {
      const { editorScriptBuilder } = require('@wix/bob-widget-services');
      const { editorReadyWrapper, createFlowAPIFabric, exportsWrapper } = require('@wix/yoshi-flow-editor/runtime/esm/editorScript.js');

      const biLogger = require('/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/target/generated/bi/createBILogger.ts').createOwnerBILogger;

      

  const { initI18n: initI18n } = require('@wix/yoshi-flow-editor/runtime/esm/i18next/init');


      

    var createExperiments = null;
    var createWidgetExperiments = null;
    

      
const { createHttpClient } = require('@wix/yoshi-flow-editor/runtime/esm/controller/httpClientProp');
    


      const sentryConfig = null;
      const experimentsConfig = {"centralized":true,"scopes":[]};
      const translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/assets/locales/messages_en.json","availableLanguages":["ar","bg","ca","cs","da","de","el","en","es","fi","fr","he","hi","hr","hu","id","it","ja","ko","lt","lv","ms","nl","no","pl","pt","ro","ru","sk","sl","sv","th","tl","tr","uk","vi","zh"]};
      const defaultTranslations = {"app.widget.displayName":"Next availability","app.widget.nickname":"Next availability widget","app.widget.availableOnline":"Available online","app.widget.bookNextSession":"Book your next session","app.widget.seeMoreDatesAndTimes":"See more dates and times","app.widget.noServiceAvailability":"This service has no availability in the next 6 months. Please contact us or check out our other services.","app.elements.title":"Choose what to display","app.elements.image":"Image","app.elements.conference":"Video conferencing badge","app.elements.serviceTitle":"Service name","app.elements.durationAndPrice":"Duration and price","app.elements.divider":"Divider","app.elements.timezone":"Time zone","app.elements.bookYourNextSession":"Book your next session","app.elements.seeMoreDatesAndTimes":"See more dates and times","app.settings.headline":"Settings","app.settings.addFirstServiceButton":"Add Your First Service","app.settings.createService":"Create a service to show it’s upcoming availability.","app.settings.chooseService":"Choose a service to display","app.settings.addNewService":"Add New Service","app.settings.chooseServiceLocation":"Choose a service location","app.layout.headline":"Layout","app.layout.subtitle":"Choose what to display","app.layout.widgetLayoutChooseLayout":"Choose a layout","app.layout.widgetLayoutTimeSlotToDisplay":"Displayed time slots","app.layout.card":"Card","app.layout.overlapping":"Overlapping","app.layout.side":"Side by side","app.layout.oneSlot":"1 time slot","app.layout.twoSlots":"2 time slots","app.layout.threeSlots":"3 time slots","app.gfpp.manageServices":"Manage Services","app.gfpp.settings":"Settings","app.design.title":"Design","app.design.badge.mainAction":"Change Design","app.design.section.title":"Design image","app.design.badge.tab":"Design badge","app.design.background":"Background"};

      const createFlowAPI = createFlowAPIFabric({
        sentryConfig,
        experimentsConfig,
        translationsConfig,
        defaultTranslations,
        biLogger,
        shouldUseEssentials: true,
        artifactId: 'next-available-widget',
        appDefId: 'd4540e90-fae1-44bf-922f-17c65b413c9f',
        optionalDeps: {
          initI18n,
          createExperiments,
          createHttpClient
        },
        localeDistPath: 'assets/locales',
      });

      const useAPIsFromExports = typeof editorScriptEntry.exports === "function";

      function wrapEditorReady(eReady) {
        try {
          return editorReadyWrapper({
            createFlowAPI,
            editorReady: eReady,
            shouldSkipAPIOverrides: useAPIsFromExports,
          });
        } catch (e) {
          console.error(e);
          throw e;
        }
      }

      let wrappedEditorReady = wrapEditorReady(editorScriptEntry.editorReady);
      if (false) {
        const hot = require('@wix/yoshi-flow-editor/build/cjs/wrappers/editorScriptHot.js').hot;
        wrappedEditorReady = hot(module, wrappedEditorReady);
      }

      let builder = editorScriptBuilder();
      if (editorScriptEntry.editorReady) {
        builder = builder.withEditorReady(wrappedEditorReady);
      }

      if (editorScriptEntry.exports) {
        builder = builder.withExports(useAPIsFromExports ? exportsWrapper(editorScriptEntry.exports) : editorScriptEntry.exports);
      }

      if (editorScriptEntry.onEvent) {
        builder = builder.withEventHandler(editorScriptEntry.onEvent);
      }
      const controllerWidgetManifests = [];
      
      const userController_0 = require('/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/Widget1/editor.controller.ts');

      const model_0 = require('/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/Widget1/model.ts').default;

      const manifest_0 = userController_0 ? userController_0.default || userController_0 : {};
      if (!manifest_0.type) {
        manifest_0.type = "d4540e90-fae1-44bf-922f-17c65b413c9f-oqtrb";
      }
      if (!manifest_0.getExports && model_0) {
        manifest_0.getExports = () => ({"d4540e90-fae1-44bf-922f-17c65b413c9f-oqtrb": model_0.getExports()});
      }
      if (userController_0 && userController_0.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "d4540e90-fae1-44bf-922f-17c65b413c9f-oqtrb", getWidgetManifest: userController_0.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_0);
  
      const userController_1 = require('/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/all presets/editor.controller.ts');

      const model_1 = require('/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/all presets/model.ts').default;

      const manifest_1 = userController_1 ? userController_1.default || userController_1 : {};
      if (!manifest_1.type) {
        manifest_1.type = "d4540e90-fae1-44bf-922f-17c65b413c9f-m81ua";
      }
      if (!manifest_1.getExports && model_1) {
        manifest_1.getExports = () => ({"d4540e90-fae1-44bf-922f-17c65b413c9f-m81ua": model_1.getExports()});
      }
      if (userController_1 && userController_1.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "d4540e90-fae1-44bf-922f-17c65b413c9f-m81ua", getWidgetManifest: userController_1.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_1);
  
      const userController_2 = require('/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/badge/editor.controller.ts');

      const model_2 = require('/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/badge/model.ts').default;

      const manifest_2 = userController_2 ? userController_2.default || userController_2 : {};
      if (!manifest_2.type) {
        manifest_2.type = "d4540e90-fae1-44bf-922f-17c65b413c9f-m1qie";
      }
      if (!manifest_2.getExports && model_2) {
        manifest_2.getExports = () => ({"d4540e90-fae1-44bf-922f-17c65b413c9f-m1qie": model_2.getExports()});
      }
      if (userController_2 && userController_2.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "d4540e90-fae1-44bf-922f-17c65b413c9f-m1qie", getWidgetManifest: userController_2.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_2);
  
      const userController_3 = require('/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/image multi not to use/editor.controller.ts');

      const model_3 = require('/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/image multi not to use/model.ts').default;

      const manifest_3 = userController_3 ? userController_3.default || userController_3 : {};
      if (!manifest_3.type) {
        manifest_3.type = "d4540e90-fae1-44bf-922f-17c65b413c9f-tabra";
      }
      if (!manifest_3.getExports && model_3) {
        manifest_3.getExports = () => ({"d4540e90-fae1-44bf-922f-17c65b413c9f-tabra": model_3.getExports()});
      }
      if (userController_3 && userController_3.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "d4540e90-fae1-44bf-922f-17c65b413c9f-tabra", getWidgetManifest: userController_3.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_3);
  
      const userController_4 = require('/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/imagebg/editor.controller.ts');

      const model_4 = require('/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/imagebg/model.ts').default;

      const manifest_4 = userController_4 ? userController_4.default || userController_4 : {};
      if (!manifest_4.type) {
        manifest_4.type = "d4540e90-fae1-44bf-922f-17c65b413c9f-jv8vy";
      }
      if (!manifest_4.getExports && model_4) {
        manifest_4.getExports = () => ({"d4540e90-fae1-44bf-922f-17c65b413c9f-jv8vy": model_4.getExports()});
      }
      if (userController_4 && userController_4.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "d4540e90-fae1-44bf-922f-17c65b413c9f-jv8vy", getWidgetManifest: userController_4.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_4);
  
      const userController_5 = require('/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/inner repeater/editor.controller.ts');

      const model_5 = require('/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/inner repeater/model.ts').default;

      const manifest_5 = userController_5 ? userController_5.default || userController_5 : {};
      if (!manifest_5.type) {
        manifest_5.type = "d4540e90-fae1-44bf-922f-17c65b413c9f-s34zt";
      }
      if (!manifest_5.getExports && model_5) {
        manifest_5.getExports = () => ({"d4540e90-fae1-44bf-922f-17c65b413c9f-s34zt": model_5.getExports()});
      }
      if (userController_5 && userController_5.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "d4540e90-fae1-44bf-922f-17c65b413c9f-s34zt", getWidgetManifest: userController_5.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_5);
  
      const userController_6 = require('/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/main layouter/editor.controller.ts');

      const model_6 = require('/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/main layouter/model.ts').default;

      const manifest_6 = userController_6 ? userController_6.default || userController_6 : {};
      if (!manifest_6.type) {
        manifest_6.type = "d4540e90-fae1-44bf-922f-17c65b413c9f-l8mc1";
      }
      if (!manifest_6.getExports && model_6) {
        manifest_6.getExports = () => ({"d4540e90-fae1-44bf-922f-17c65b413c9f-l8mc1": model_6.getExports()});
      }
      if (userController_6 && userController_6.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "d4540e90-fae1-44bf-922f-17c65b413c9f-l8mc1", getWidgetManifest: userController_6.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_6);
  
      const userController_7 = require('/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/main repeater/editor.controller.ts');

      const model_7 = require('/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/main repeater/model.ts').default;

      const manifest_7 = userController_7 ? userController_7.default || userController_7 : {};
      if (!manifest_7.type) {
        manifest_7.type = "d4540e90-fae1-44bf-922f-17c65b413c9f-lcchp";
      }
      if (!manifest_7.getExports && model_7) {
        manifest_7.getExports = () => ({"d4540e90-fae1-44bf-922f-17c65b413c9f-lcchp": model_7.getExports()});
      }
      if (userController_7 && userController_7.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "d4540e90-fae1-44bf-922f-17c65b413c9f-lcchp", getWidgetManifest: userController_7.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_7);
  
      const userController_8 = require('/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/presets main/editor.controller.ts');

      const model_8 = require('/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/presets main/model.ts').default;

      const manifest_8 = userController_8 ? userController_8.default || userController_8 : {};
      if (!manifest_8.type) {
        manifest_8.type = "d4540e90-fae1-44bf-922f-17c65b413c9f-i7n7k";
      }
      if (!manifest_8.getExports && model_8) {
        manifest_8.getExports = () => ({"d4540e90-fae1-44bf-922f-17c65b413c9f-i7n7k": model_8.getExports()});
      }
      if (userController_8 && userController_8.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "d4540e90-fae1-44bf-922f-17c65b413c9f-i7n7k", getWidgetManifest: userController_8.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_8);
  
      const userController_9 = require('/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/slots layouter/editor.controller.ts');

      const model_9 = require('/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/slots layouter/model.ts').default;

      const manifest_9 = userController_9 ? userController_9.default || userController_9 : {};
      if (!manifest_9.type) {
        manifest_9.type = "d4540e90-fae1-44bf-922f-17c65b413c9f-yeq1v";
      }
      if (!manifest_9.getExports && model_9) {
        manifest_9.getExports = () => ({"d4540e90-fae1-44bf-922f-17c65b413c9f-yeq1v": model_9.getExports()});
      }
      if (userController_9 && userController_9.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "d4540e90-fae1-44bf-922f-17c65b413c9f-yeq1v", getWidgetManifest: userController_9.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_9);
  
      const userController_10 = require('/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/test/editor.controller.ts');

      const model_10 = require('/home/builduser/work/f35f652dcafd0a82/packages/next-available-widget/src/components/test/model.ts').default;

      const manifest_10 = userController_10 ? userController_10.default || userController_10 : {};
      if (!manifest_10.type) {
        manifest_10.type = "d4540e90-fae1-44bf-922f-17c65b413c9f-eavar";
      }
      if (!manifest_10.getExports && model_10) {
        manifest_10.getExports = () => ({"d4540e90-fae1-44bf-922f-17c65b413c9f-eavar": model_10.getExports()});
      }
      if (userController_10 && userController_10.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "d4540e90-fae1-44bf-922f-17c65b413c9f-eavar", getWidgetManifest: userController_10.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_10);

      if (editorScriptEntry.getAppManifest || controllerWidgetManifests.length) {
        const customGetAppManifest = async (...params) => {
          const [{ appManifestBuilder }, editorSDK, contextParams] = params;
          const flowAPI = await createFlowAPI(editorSDK, contextParams);
          controllerWidgetManifests.forEach(({ widgetId, getWidgetManifest}) => {
            appManifestBuilder.configureWidget(widgetId, (builder) => getWidgetManifest(builder, editorSDK, flowAPI));
          });

          if (editorScriptEntry.getAppManifest) {
            return editorScriptEntry.getAppManifest(...params, flowAPI);
          }

          return appManifestBuilder.build();
        };
        builder = builder.withAppManifest(customGetAppManifest);
      }
      var result = builder.build();
      module.exports = result;
  }
  
