import { fetchWidgetsStageData } from '@wix/bob-widget-services';
import { WIDGET_IDS } from '../utils/constants';
import type {
  FlowEditorSDK,
  EditorScriptFlowAPI,
} from '@wix/yoshi-flow-editor';

export const getAppManifestWrapper = async ({
  options,
  editorSDK,
  contextParams,
  flowAPI,
}: {
  options: any;
  editorSDK: FlowEditorSDK;
  contextParams: any;
  flowAPI: EditorScriptFlowAPI;
}) => {
  const t = flowAPI.translations.t;
  const { appManifestBuilder } = options;
  console.log('options:', options);
  console.log('editorSDK:', editorSDK);
  console.log('contextParams:', contextParams);
  console.log('flowAPI:', flowAPI);

  // REMOVE GFPPs
  appManifestBuilder.configureWidget(WIDGET_IDS.IMAGE, (widgetBuilder) => {
    // console.log({ widgetBuilder });
    widgetBuilder
      .gfpp()
      .set('mainAction1', {
        label: t('app.gfpp.manageServices'),
        onClick: () => {
          editorSDK.editor.openDashboardPanel('token', {
            url: '/bookings/services',
            closeOtherPanels: false,
          });
        },
      })
      .set('connect', {
        behavior: 'HIDE',
      })
      .set('link', {
        behavior: 'HIDE',
      })
      .set('help', {
        id: '92b799cc-7840-4eae-9fc7-baccc19b6696',
      });
  });

  if (contextParams.origin?.type === 'RESPONSIVE') {
    appManifestBuilder.configureWidget(WIDGET_IDS.BADGE, (widgetBuilder) => {
      // console.log({ widgetBuilder });
      widgetBuilder
        .gfpp()
        .set('mainAction1', {
          behavior: 'HIDE',
        })
        .set('connect', {
          behavior: 'HIDE',
        })
        .set('design', {
          role: 'DEFAULT',
        })
        .set('link', {
          behavior: 'HIDE',
        })
        .set('help', {
          id: '92b799cc-7840-4eae-9fc7-baccc19b6696',
        });
    });
  } else {
    appManifestBuilder.configureWidget(WIDGET_IDS.BADGE, (widgetBuilder) => {
      // console.log({ widgetBuilder });
      widgetBuilder
        .gfpp()
        .set('mainAction1', {
          label: t('app.design.badge.mainAction'),
          actionType: 'design', 
          actionId: ''
        })
        .set('connect', {
          behavior: 'HIDE',
        })
        .set('design', {
          // behavior: 'SHOW',
          target: {
            role: 'badge2'
          }
        })
        .set('link', {
          behavior: 'HIDE',
        })
        .set('help', {
          id: '92b799cc-7840-4eae-9fc7-baccc19b6696',
        });
    });
  }

  appManifestBuilder.configureWidget(
    WIDGET_IDS.MAIN_REPEATER,
    (widgetBuilder) => {
      // console.log({ widgetBuilder });
      widgetBuilder
        .gfpp()
        .set('mainAction1', {
          label: t('app.gfpp.manageServices'),
          onClick: () => {
            editorSDK.editor.openDashboardPanel('token', {
              url: '/bookings/services',
              closeOtherPanels: false,
            });
          },
        })
        .set('settings', {
          behavior: 'HIDE',
        })
        .set('connect', {
          behavior: 'HIDE',
        })
        .set('link', {
          behavior: 'HIDE',
        })
        .set('help', {
          id: '92b799cc-7840-4eae-9fc7-baccc19b6696',
        });
    },
  );

  appManifestBuilder.configureWidget(
    WIDGET_IDS.INNER_REPEATER,
    (widgetBuilder) => {
      // console.log({ widgetBuilder });
      widgetBuilder
        .gfpp()
        .set('settings', {
          behavior: 'HIDE',
        })
        .set('connect', {
          behavior: 'HIDE',
        })
        .set('link', {
          behavior: 'HIDE',
        })
        .set('help', {
          id: '92b799cc-7840-4eae-9fc7-baccc19b6696',
        });
    },
  );

  appManifestBuilder.configureWidget(
    WIDGET_IDS.ALL_PRESETS,
    (widgetBuilder) => {
      // console.log({ widgetBuilder });
      widgetBuilder
        .gfpp()
        .set('mainAction1', {
          label: t('app.gfpp.manageServices'),
          onClick: () => {
            editorSDK.editor.openDashboardPanel('token', {
              url: '/bookings/services',
              closeOtherPanels: false,
            });
          },
        })
        .set('mainAction2', {
          // HOW TO CONNECT TO SETTINGS BUTTON?
          label: t('app.gfpp.settings'),
          actionId: 'settingsPanel',
        })
        .set('settings', {
          behavior: 'HIDE',
        })
        .set('connect', {
          behavior: 'HIDE',
        })
        .set('link', {
          behavior: 'HIDE',
        })
        .set('help', {
          id: '92b799cc-7840-4eae-9fc7-baccc19b6696',
        });
    },
  );

  // MOBILE GFPP control
  appManifestBuilder.configureWidget(
    WIDGET_IDS.ALL_PRESETS,
    (widgetBuilder) => {
      // console.log({ widgetBuilder });
      widgetBuilder
        .gfpp('mobile')
        .set('mainAction1', {
          label: t('app.gfpp.manageServices'),
          behavior: 'HIDE',
        })
        .set('settings', {
          behavior: 'HIDE',
        })
        .set('connect', {
          behavior: 'HIDE',
        })
        .set('link', {
          behavior: 'HIDE',
        })
        .set('help', {
          id: '92b799cc-7840-4eae-9fc7-baccc19b6696',
        });
    },
  );
  const baseManifest = await fetchWidgetsStageData(options);
  const manifest = appManifestBuilder.withJsonManifest(baseManifest).build();

  // Remove element GFPP from mobile view
  manifest.controllersStageData[
    WIDGET_IDS.ALL_PRESETS
  ].default.gfpp.mobile.iconButtons.add = { behavior: 'HIDE' };
  return manifest;
};